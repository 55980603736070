@mixin cssLoader {
  .lds-dual-ring {
    width: 30px;
    height: 30px;
    border: 5px solid black;
    border-color: black transparent black transparent;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s linear infinite;
    //position: absolute;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}
