*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  a {
    color: inherit;
    &:hover {
      color: #3A4F41;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.App {
  width: 100vw;
  height: 100vh;
  .filter_container{
    width: 100%;
    height: 10vh;
  }
  .map_container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 90vh;
  }
}
*.title {
  &::selection {
    background: transparent
  }
}
