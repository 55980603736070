@import "mixins";

.date_picker_container {
  width: 90%;
  position: relative;
  .title {
    @include flex(4);
    margin-bottom: 5px;
    cursor: default;
    width: 100%;
    font-size: 12px;
  }
  .header {
    z-index: 10;
    cursor: pointer;
    height: 30px;;
    border: 1px solid black;
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-content: center;
    align-items: center;
    justify-items: center;
    position: relative;
    background: white;
    p{
      padding-left: 10px;
      grid-column: 1 / 3;
      font-size: 10px;
      width: 100%;
      text-align: left;
    }
    svg {
      position: absolute;
      grid-column: 2 / 3;
      //right: -30px;
      //transform: scale(1.5);
    }
  }
  .content {
    width: 210px;
    cursor: pointer;
    max-height: 230px;
    position: absolute;
    z-index: 10;
    background: white;
    border: 1px solid black;
    margin-top: -1px;
    padding-bottom: 5px;
    //min-height: 175px;
  }
}
