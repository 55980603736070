@import "mixins";

.map_blocker {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 9;
}
