div[role="button"] {
  &:active, &:focus {
    outline: none;
  }
  .marker_content {
    max-height: 50px;
    overflow: scroll;
  }
}
.gm-style-iw.gm-style-iw-c {
  padding: 10px 25px !important;
}
