@import "mixins";
.dropdown_menu_container {
  width: 90%;
  position: relative;
  .title {
    @include flex(4);
    margin-bottom: 5px;
    cursor: default;
    width: 100%;
    font-size: 12px;
  }
  .header {
    z-index: 10;
    height: 30px;;
    border: 1px solid black;
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-content: center;
    align-items: center;
    justify-items: center;
    position: relative;
    background: white;
    cursor: pointer;
    p{
      white-space: nowrap;
      overflow: hidden;
      padding-left: 10px;
      width: 100%;
      grid-column: 1 / 3;
      font-size: 10px;
      cursor: pointer;
      text-align: left;
      &::selection {
        background: transparent;
      }
    }
    svg {
      position: absolute;
      grid-column: 2 / 3;
    }
  }
  .content {
    cursor: pointer;
    //width: 100%;
    max-height: 200px;
    position: absolute;
    overflow: scroll;
    z-index: 10;
    background: white;
    border: 1px solid black;
    min-width: 100%;
    width: auto;
    //border-bottom: 1px solid black;
    //border-right: 1px solid black;
    .option {
      padding: 0 5px;
      @include flex(1);
      text-align: center;
      min-width: 100%;
      white-space: nowrap;
      width: auto;
      height: 30px;
      transition: 0.2s ease;
      font-size: 10px;
      &:hover {
        background: #617799;
      }
      &::selection {
        background: transparent;
      }
    }
    .selected {
      background: #617799;
    }
  }
}
