@import "mixins";

#not_found {
  width: 100vw;
  heighT: 100vh;
  @include flex(1);
  flex-direction: column;
  background: #B9314F;
  color: white;
  animation: blink 3s linear infinite;
  h1 {
    font-size: 4em;
    margin-bottom: 50px;
  }
  h2 {
    font-size: 2em;
  }
}

.not_found_label {
  margin-top: 20px
}

@keyframes blink {
  0% {
    background: #B9314F;
  }
  50% {
    background: #b35468;
  }
  100% {
    background: #B9314F;
  }
}
