@import "mixins";
.text_input_container{
  width: 90%;
  z-index: 10;
  .title {
    @include flex(4);
    margin-bottom: 5px;
    cursor: default;
    width: 100%;
    font-size: 12px;
  }
  input {
    height: 30px;
    width: 100%;
    border: 1px solid black;
    //text-align: center;
    padding-left: 10px;
    font-size: 10px;
    vertical-align: baseline;
  }
  .disabled {
    background: rgb(224, 224, 224);
    border: 1px solid rgb(168, 168, 168)
  }

}
