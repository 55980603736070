@import "mixins";

.multiple_select_container {
  width: 90%;
  position: relative;
  height: auto;
  .title {
    @include flex(4);
    margin-bottom: 5px;
    cursor: default;
    width: 100%;
    font-size: 12px;
  }
  .expand_button{
    width: 20px;
    position: absolute;
    right: -20px;
    height: 30px;
    @include flex(1);
    cursor: pointer;
  }
  .header {
    z-index: 10;
    height: 30px;;
    border: 1px solid black;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 30px;
    position: relative;
    background: white;
    cursor: pointer;
    font-size: 10px;
    .values_in_menu {
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      overflow: scroll;
      width: 100%;
      height: 30px;
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      padding: 0 5px;
      grid-gap: 5px;
      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
    }
    .values {
      background: white;
      border: 1px solid black;
      overflow: scroll;
      width: calc(100% + 2px);
      max-height: 200px;
      display: grid;
      grid-template-rows: 1fr;
      grid-auto-flow: row;
      //align-items: center;
      padding: 10px 5px 20px 5px;
      left: -1px;
      grid-gap: 5px;
      position: absolute;
      top: 28px;
      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
      .value {
        height: 30px;
        overflow: scroll;
        justify-content: flex-start;
      }
      .selected_option {
        height: 30px;
        align-items: center;
        width: 100%;
        font-size: 10px;
      }
    }
    .add {
      padding-right: 10px;
      width: 30px;
      height: 30px;
      @include flex(1);
    }
  }
  .content {
    cursor: pointer;
    width: 100%;
    max-height: 200px;
    position: absolute;
    overflow: scroll;
    z-index: 10;
    background: white;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    .search_bar {
      width: 100%;
      height: 30px;
      display: grid;
      grid-template-columns: 1fr 30px;
      position: relative;
      .search {
        padding-right: 5px;
        width: 100%;
        height: 100%;
        @include flex(1);
        #fa_confirm ::before{
          background: transparent;
        }
      }
      .input{
        padding: 0 5px;
        width: 100%;
        height: 30px;
        input {
          padding-left: 10px;
          width: 100%;
          height: 100%;
          border: none;
          border-bottom: 2px solid black;
          &:focus {
            outline: none;
          }
        }
      }
    }

    .options {
      width: 100%;
      height: 100%;
      display: grid;
      grid-auto-flow: row;
      justify-items: stretch;
      padding: 0;
      margin: 0;
      .value {
        overflow: scroll;
      }
    }
  }
  .select {
    .search_bar {
      grid-template-columns: 1fr;
      input {
        width: 100%;
      }
    }
  }
}
