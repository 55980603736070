@import "mixins";
@import "loader";
#filters_container{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: calc(100vw - 300px) 300px;
  justify-content: stretch;
  z-index: 10;
  .left{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 4px;
    justify-content: center;
    justify-items: center;
    align-content: center;
  }
  .right {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 100px);
    justify-content: center;
    justify-items: center;
    align-items: center;
    .basic {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
    }
    .full {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
    }
    .assign_button {
      grid-row: 1 / 2;
      position: relative;
      grid-column: 1 / 2;
      width: 100%;
      height: 100%;
      @include flex(1);
      flex-direction: column;
      .label {
        p{
          font-size: 12px;
          margin-bottom: 5px;
        }
      }
      .button {
        svg {
          background: transparent;
          color: #3A4F41;
          cursor: pointer;
        }
      }
    }
    .refresh_button {
      height: 100%;
      width: 100%;

      .refresh {
        @include flex(1);
        flex-direction: column;
        width: 100%;
        @include cssLoader();
        height: 100%;
        .label {
          @include flex(1);
          p{
            font-size: 12px;
            margin-bottom: 5px;
          }
        }
        .button{
          cursor: pointer;
          position: relative;
          @include flex(1);
          height: 30px;
          width: 30px;
        }
      }

      .no_click {
        height: 100%;
        width: 100%;
        &:hover {
          cursor: progress;
        }
      }
    }
    .hidden {
      visibility: hidden;
    }
  }
}
